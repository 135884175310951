<template>
  <page>

    <div class="d-flex flex-column flex-sm-row justify-space-between align-center">
      <h1>
        {{ _('Twoje nagrania') }}:
      </h1>
      <div class="upload-buttons">
        <v-btn x-large color="primary"  @click.stop="recordShow">
          <v-icon>
            mdi-microphone
          </v-icon>
          <span class="d-none d-md-inline">
            {{ _('Stwórz nowe nagranie') }}
          </span>
          <span class="d-inline d-md-none">
            {{ _('Nagraj') }}
          </span>
        </v-btn>
      </div>
    </div>

    <div v-if="!recordingsLoaded" class="mt-2">
      <v-skeleton-loader
        max-width="400"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        max-width="350"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        max-width="450"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </div>

    <component-error v-else-if="recordingsError" @click="loadRecordings"/>

    <v-list two-line v-else>
      <v-list-item
        v-for="(item, index) in recordings"
        :key="index"
      >

        <v-list-item-avatar>
          <v-icon v-if="item.uploaded && item.downloaded"
            :class="itemClass(item)"
            dark
            @click="playShow(item.hash)"
          >
            {{ itemIcon(item) }}
          </v-icon>
          <v-icon v-else
            :class="itemClass(item)"
            dark
          >
            {{ itemIcon(item) }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <template v-if="editingIndex != index || editingType != 'list'">
              <v-btn icon x-small @click="editStart(index, 'list')" class="d-none d-sm-inline">
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
              {{ item.title }}
            </template>
            <div class="edit-title" v-else>
              <v-text-field filled dense v-model="editingTitle" ref="title" @blur="editStop({code:'Enter'})" @keyup="editStop"/>
            </div>
          </v-list-item-title>
          <v-list-item-subtitle style="height: 18px">
            <span v-if="!itemProgress(item.hash)" :class="{ 'edit-subtitle': editingIndex == index && editingType == 'list'}">
              <span class="d-none d-sm-inline">
                {{ prettyTime(item.time) }}
                |
              </span>
              {{ itemInfo(item) }}
              <a href="#" @click.prevent="recordDownload(item.hash)" v-if="!item.pending && item.ready && !item.downloaded">
                {{ _('Spróbuj ponownie') }}
              </a>
              <a href="#" @click.prevent="recordUpload(item.hash)" v-if="item.pending && !item.ready && !item.uploaded">
                {{ _('Spróbuj ponownie') }}
              </a>
            </span>
            <v-progress-linear v-else :value="item.percent" class="mt-1"/>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <div class="d-flex align-center">
            <div class="text-h6 item-duration">
              {{ fileDuration(item.duration) }}
            </div>
            <v-btn @click="itemDownload(item.hash)" :disabled="!item.uploaded" class="d-none d-sm-inline">
              <v-icon small>
                mdi-file-document-plus-outline
              </v-icon>
              {{ _('Stwórz notatki') }}
            </v-btn>
            <v-menu offset-x left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="itemDownload(item.hash)" v-if="item.uploaded">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-document-plus-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ _('Stwórz notatki') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="playShow(item.hash)" v-if="!item.pending || item.downloaded">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-play
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ _('Odtwórz nagranie') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="itemDownload(item.hash)" v-if="item.uploaded && item.downloaded">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-download
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ _('Pobierz nagranie') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="recordDownload(item.hash)" v-if="!item.pending && item.ready && !item.downloaded">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-sync
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ _('Synchronizuj') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="recordUpload(item.hash)" v-if="item.pending && !item.ready && !item.uploaded">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-sync
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ _('Synchronizuj') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="itemDelete(item.hash)">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ _('Usuń nagranie') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-list-item-action>

      </v-list-item>
    </v-list>

    <v-dialog
      v-model="playOpened"
      max-width="446"
    >
      <v-card>

        <v-card-title class="justify-space-between">
          <span>
            {{ _('Odtwórz nagranie') }}
          </span>
          <v-btn icon @click="playClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <div class="play-info">

            <v-form>
              <v-text-field
                v-model="playTitle"
                :label="_('Nazwa nagrania')"
                readonly
                @focus="$event.target.blur()"
              />
            </v-form>

            <v-slider
              v-model="playSlider"
              :min="0"
              :max="10000"
              class="flex-column"
              @change="playChanged"
            >
              <template v-slot:prepend>
                <div>
                  {{ fileDuration(playPosition) }}
                </div>
                <div>
                  -{{ fileDuration(playDuration-playPosition) }}
                </div>
              </template>
            </v-slider>

            <div class="play-buttons">
              <div class="d-flex align-center">
                <v-menu top :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn large rounded class="mr-3 d-none d-sm-inline" v-on="on">
                      <v-icon v-if="playMuted">mdi-volume-off</v-icon>
                      <v-icon v-else-if="playVolume == 0">mdi-volume-mute</v-icon>
                      <v-icon v-else-if="playVolume < 35">mdi-volume-low</v-icon>
                      <v-icon v-else-if="playVolume < 70">mdi-volume-medium</v-icon>
                      <v-icon v-else>mdi-volume-high</v-icon>
                    </v-btn>
                  </template>
                  <div class="play-volume">
                    <v-slider
                      dense
                      vertical
                      v-model="playVolume"
                      :min="0"
                      :max="100"
                      v-if="!playMuted"
                      @change="playSetVolume"
                    />
                    <v-slider
                      dense
                      vertical
                      :value="0"
                      :min="0"
                      :max="100"
                      disabled
                      v-else
                    />
                    <v-btn icon @click="playMute" v-if="!playMuted">
                      <v-icon v-if="playVolume == 0">mdi-volume-mute</v-icon>
                      <v-icon v-else-if="playVolume < 35">mdi-volume-low</v-icon>
                      <v-icon v-else-if="playVolume < 70">mdi-volume-medium</v-icon>
                      <v-icon v-else>mdi-volume-high</v-icon>
                    </v-btn>
                    <v-btn icon @click="playUnmute" v-else>
                      <v-icon>
                        mdi-volume-off
                      </v-icon>
                    </v-btn>
                  </div>
                </v-menu>
                <v-btn large rounded @click="playRewind">
                  <v-icon>mdi-rewind</v-icon>
                </v-btn>
                <v-btn large rounded @click="playBack">
                  <v-icon>mdi-rewind-15</v-icon>
                </v-btn>
                <v-btn x-large color="primary" rounded v-if="!playPlaying" @click="playStart">
                  <v-icon x-large>mdi-play</v-icon>
                </v-btn>
                <v-btn x-large color="primary" rounded v-else @click="playPause">
                  <v-icon x-large>mdi-pause</v-icon>
                </v-btn>
                <v-btn large rounded @click="playForward">
                  <v-icon>mdi-fast-forward-15</v-icon>
                </v-btn>
                <v-btn large rounded @click="playEnd">
                  <v-icon>mdi-fast-forward</v-icon>
                </v-btn>
                <v-btn large rounded @click="itemDownload(playHash)" class="ml-3 d-none d-sm-inline">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </div>
            </div>

          </div>

        </v-card-text>

      </v-card>

    </v-dialog>

    <v-dialog
      v-model="recordOpened"
      max-width="446"
      :persistent="!canStart"
    >
      <v-card>

        <v-card-title class="justify-space-between">
          <span>
            {{ _('Stwórz nowe nagranie') }}
          </span>
          <v-btn icon @click="recordOpened = false" v-if="canStart">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <div class="record-info">

            <div v-if="recordAllowed">

              <v-form>
                <v-text-field
                  v-model="recordName"
                  :label="_('Nazwa nagrania')"
                  clearable
                />
              </v-form>

              <div class="record-time" ref="timer">
                {{ recordTime }}
              </div>

              <div class="record-bars">
                <div>
                  <div v-for="(bar, index) in recordBars" :key="index" :style="{ 'height': bar*2 + 'px' }">
                  </div>
                </div>
              </div>

              <div class="record-buttons">
                <div>
                  <v-btn v-if="canResume" @click="recordCancel">
                    <v-icon>mdi-close</v-icon>
                    {{ _('Odrzuć') }}
                  </v-btn>
                </div>
                <div>
                  <v-btn x-large color="red" rounded dark v-if="canStart" @click="recordStart">
                    <v-icon x-large>mdi-record</v-icon>
                  </v-btn>
                  <v-btn x-large color="red" rounded dark v-if="canPause" @click="recordPause">
                    <v-icon x-large>mdi-pause</v-icon>
                  </v-btn>
                  <v-btn x-large color="red" rounded dark v-if="canResume" @click="recordResume">
                    <v-icon x-large>mdi-record</v-icon>
                  </v-btn>
                </div>
                <div>
                  <v-btn color="primary" v-if="canResume" @click="recordAdd">
                    <v-icon>mdi-check</v-icon>
                    {{ _('Zapisz ')}}
                  </v-btn>
                </div>
              </div>

            </div>

            <div class="record-blocked" v-else>
              <v-icon>
                mdi-microphone-off
              </v-icon>
              <div class="mb-3">
                {{ _('Nagrywanie wymaga udzielenia dostępu do mikrofonu.') }}
              </div>
              <v-btn color="primary" @click="enableMicrophone">
                {{ _('Włącz mikrofon') }}
              </v-btn>
            </div>

          </div>

        </v-card-text>

      </v-card>

    </v-dialog>

    <v-dialog
      v-model="pendingOpened"
      max-width="700"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>

        <v-card-title class="justify-space-between">
          <span>
            {{ _('Niezapisane nagrania') }}
          </span>
          <v-btn icon @click="pendingOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <div class="dialog-list">

            <div>
              {{ _('Znaleźliśmy na Twoim komputerze nagrania, które jeszcze nie zostały zapisane na serwerze.') }}
            </div>

            <v-list two-line>

              <v-list-item :key="index" v-for="(item, index) in pendingList">

                <v-list-item-content>
                  <v-list-item-title>
                    <template v-if="editingIndex != index || editingType != 'pending'">
                      <v-btn icon x-small @click="editStart(index, 'pending')" :disabled="!item.title" class="d-none d-sm-inline">
                        <v-icon small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      {{ item.title }}
                    </template>
                    <div class="edit-title" v-else>
                      <v-text-field filled dense v-model="editingTitle" ref="title" @blur="editStop({code:'Enter'})" @keyup="editStop"/>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle style="height:18px" :class="{ 'edit-subtitle': editingIndex == index && editingType == 'youtube'}">
                    <span v-text="prettyTime(item.time)"/>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <div class="d-flex align-center">
                    <div v-if="item.duration" class="text-h6">
                      {{ fileDuration(item.duration) }}
                    </div>
                    <div class="ml-2">
                      <v-btn icon @click="pendingRemove(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn icon color="green" @click="pendingSave(index)" class="d-sm-none">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn color="primary" @click="pendingSave(index)" class="ml-2 d-none d-sm-inline">
                        <v-icon>mdi-check</v-icon>
                        {{ _('Zapisz') }}
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-action>

              </v-list-item>

            </v-list>

          </div>

        </v-card-text>

      </v-card>

    </v-dialog>

    <v-dialog
      v-model="deleteOpened"
      max-width="430"
    >
      <v-card>

        <v-card-title class="justify-space-between">
          <span>
            {{ _('Usunięcie nagrania') }}
          </span>
          <v-btn icon @click="deleteOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          {{ _('Nagranie zostanie usunięte ze wszystkich Twoich urządzeń. Czy na pewno chcesz kontynuować?') }}
        </v-card-text>

        <v-card-actions class="justify-center pb-5">
          <v-btn @click="deleteOpened = false" class="px-6" style="min-width: 110px">
            {{ _('Anuluj') }}
          </v-btn>
          <v-btn @click="itemDelete2" color="red" dark class="px-6" style="min-width: 110px">
            {{ _('Usuń') }}
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <audio ref="audio" @timeupdate="playUpdated" @ended="playPlaying=false" @volumechange="playGetVolume"></audio>

    <a ref="download" href="#" download="" style="display:none"></a>

  </page>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Page from '../Page.vue';
import ComponentError from '../components/Error.vue';

const axios = require('axios');

import MD5 from "crypto-js/md5";

/*global Config*/

export default {
  name: 'PageRecordings',
  components: {
    Page,
    ComponentError,
  },
  data() {
    return {
      db: null,
      recordOpened: false,
      recordAllowed: false,
      recordActive: false,
      recordHash: null,
      recordName: "",
      recordLength: 0,
      recordBars: [],
      recordChunks: [],
      recordInterval: null,
      recordBlink: 0,
      mediaStream: null,
      mediaRecorder: null,
      editingIndex: 0,
      editingType: null,
      editingTitle: 0,
      progress: {},
      pendingOpened: false,
      pendingList: [],
      deleteOpened: false,
      deleteHash: "",
      playOpened: false,
      playHash: "",
      playTitle: "",
      playDuration: 0.0,
      playPosition: 0.0,
      playSlider: 0,
      playPlaying: false,
      playVolume: 100,
      playMuted: false,
    };
  },
  mounted() {
    this.openDatabase();
  },
  computed: {
    recordTime() {
      var ret = "";
      var time = Math.ceil(this.recordLength);
      if(time >= 3600) {
        const h = Math.floor(time / 3600);
        ret = h.toString() + ":";
        time -= h * 3600;
      }
      const m = Math.floor(time / 60);
      const s = time - m * 60;
      if(ret)
        ret += ("0" + m.toString()).substr(-2);
      else
        ret += m.toString();
      ret += ":" + ("0" + s.toString()).substr(-2);
      return ret;
    },
    canStart() {
      return !this.recordActive && !this.recordLength;
    },
    canPause() {
      return this.recordActive;
    },
    canResume() {
      return !this.recordActive && this.recordLength;
    },
    ...mapGetters(["recordings", "recordingsLoaded", "recordingsError"]),
  },
  methods: {
    loadList() {
      return this.loadRecordings().then((response) => {
        if(response.data.pending) window.setTimeout(() => this.loadList(), 5000);
        this.pendingList = [];
        this.recordings.forEach((item) => {
          const transaction = this.db.transaction("files", "readwrite");
          const store = transaction.objectStore("files");
          const request = store.get(item.hash);
          request.onsuccess = (event) => {
            item.pending = false;
            const data = event.target.result;
            if(!item.downloaded && item.ready) {
              if(data && data.data && data.type == "audio/mp3") {
                item.downloaded = true;
                item.uploaded = true;
              }
              else if(item.ready) {
                this.recordDownload(item.hash);
              }
            }  else {
              item.pending = false;
            }
          };
        });
      });
    },
    openDatabase() {
      const request = window.indexedDB.open("recordings", 1);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const store = db.createObjectStore("files", { keyPath: "hash" });
        store.createIndex("time", "time", { unique: false });
      };
      request.onsuccess = (event) => {
        this.db = event.target.result;
        this.loadList().then(() => {
          const transaction = this.db.transaction("files", "readonly");
          const store = transaction.objectStore("files");
          const request = store.openCursor();
          request.onsuccess = (event) => {
            const cursor = event.target.result;
            if(cursor) {
              const hash = cursor.value.hash;
              var ok = false;
              this.recordings.forEach((item) => {
                if(item.hash == hash) ok = true;
              });
              if(!ok) {
                if(cursor.value.incomplete) {
                  this.pendingOpened = true;
                  this.pendingList.push({
                    hash,
                    duration: cursor.value.length,
                    time: cursor.value.time,
                    title: cursor.value.name,
                  });
                } else {
                  this.addRecording({
                    hash: hash,
                    duration: cursor.value.length,
                    title: cursor.value.name,
                    uploaded: false,
                    downloaded: true,
                    ready: false,
                    pending: true,
                    time: cursor.value.time,
                  });
                  this.recordUpload(hash);
                }
              }
              cursor.continue();
            } else {
              this.pendingList.sort((a, b) => b.time - a.time);
              this.$nextTick(() => this.finishRecordings());
            }
          };
        });
      };
    },
    editStart(index, type) {
      this.editingIndex = index;
      this.editingType = type;
      switch(type) {
        case "list":
          this.editingTitle = this.recordings[index].title;
          break;
        case "pending":
          this.editingTitle = this.pendingList[index].title;
          break;
      }
      this.$nextTick(() => document.querySelector("DIV.edit-title INPUT").focus());
    },
    editStop(event) {
      if(event.code != "Enter" && event.code != "Escape") return;
      if(event.code == "Enter" && this.editingTitle.trim().length > 0) switch(this.editingType) {
        case "list":
          this.renameRecording({
            hash: this.recordings[this.editingIndex].hash,
            title: this.editingTitle.trim(),
          });
          break;
        case "pending":
          this.pendingList[this.editingIndex].title = this.editingTitle.trim();
          {
            const transaction = this.db.transaction("files", "readwrite");
            const store = transaction.objectStore("files");
            const request = store.get(this.pendingList[this.editingIndex].  hash);
            request.onsuccess = (event) => {
              const data = event.target.result;
              if(data) {
                data.name = this.editingTitle;
                store.put(data);
              }
            };
          }
          break;
      }
      this.editingType = null;
    },
    itemClass(item) {
      if(item.hash in this.progress) {
        if(this.progress[item.hash].type == "download")
          return "grey downloading";
        else if(this.progress[item.hash].type == "upload")
          return "primary uploading";
      } else {
        if(item.ready && !item.pending && !item.downloaded) {
          return "error";
        } else if(!item.ready && item.pending && !item.uploaded) {
          return "error";
        } else if(item.downloaded && item.uploaded && item.ready) {
          return "success";
        } else {
          return "grey";
        }
      }
    },
    itemIcon(item) {
      if(item.hash in this.progress) {
        if(this.progress[item.hash].type == "download")
          return "mdi-download";
        else if(this.progress[item.hash].type == "upload")
          return "mdi-upload";
      } else {
        if(!item.pending && item.ready && !item.downloaded) {
          return "mdi-close";
        } else if(!item.ready && item.pending && !item.uploaded) {
          return "mdi-close";
        } else if(item.downloaded && item.uploaded && item.ready) {
          return "mdi-play";
        } else {
          return "mdi-loading loading";
        }
      }
    },
    itemInfo(item) {
      if(!item.pending && item.ready && !item.downloaded) {
        return this._("Nie udało się pobrać pliku.");
      } else if(!item.ready && item.pending && !item.uploaded) {
        return this._("Nie udało się wysłać pliku.");
      } else if(item.downloaded && item.uploaded && item.ready) {
        return this._("Nagranie zsynchronizowane.");
      } else {
        return this._("Trwa przetwarzanie...");
      }
    },
    itemProgress(hash) {
      return this.progress[hash];
    },
    itemDownload(hash) {
      const transaction = this.db.transaction("files", "readonly");
      const store = transaction.objectStore("files");
      const request = store.get(hash);
      request.onsuccess = (event) => {
        const data = event.target.result;
        const url = URL.createObjectURL(data.data);
        this.$refs["download"].href = url;
        var name = data.name;
        const item = this.recordings.find((item) => item.hash == hash);
        if(item) name = item.title;
        switch(data.type) {
          case "audio/mp3": case "audio/mpeg":
            name += ".mp3"; break;
          case "audio/mp4":
            name += ".m4a"; break;
          case "audio/webm":
            name += ".webm"; break;
          case "audio/ogg":
            name += ".ogg"; break;
        }
        this.$refs["download"].download = name;
        this.$refs["download"].click();
      };
    },
    itemDelete(hash) {
      this.deleteOpened = true;
      this.deleteHash = hash;
    },
    itemDelete2() {
      const item = this.recordings.find((item) => item.hash == this.deleteHash);
      if(item.uploaded) {
        this.deleteRecording({ hash: this.deleteHash });
      }
      if(item.downloaded) {
        const transaction = this.db.transaction("files", "readwrite");
        const store = transaction.objectStore("files");
        const request = store.delete(this.deleteHash);
        request.onsuccess = () => {
          this.removeRecording({ hash: this.deleteHash });
        };
      }
      this.deleteOpened = false;
    },
    enableMicrophone() {
      navigator.mediaDevices.getUserMedia({ 
        video: false, 
        audio: true,
      })
      .then((stream) => { 
        stream.getAudioTracks().forEach((track) => track.stop());
        this.recordAllowed = true; 
      });
    },
    playShow(hash) {
      this.playHash = hash;
      const transaction = this.db.transaction("files", "readonly");
      const store = transaction.objectStore("files");
      const request = store.get(hash);
      request.onsuccess = (event) => {
        const data = event.target.result;
        const url = URL.createObjectURL(data.data);
        this.$refs["audio"].addEventListener("loadeddata", () => {
          this.playDuration = this.$refs["audio"].duration;
          this.playPosition = 0.0;
          this.playSlider = 0;
          const item = this.recordings.find((item) => item.hash == hash);
          this.playTitle = item.title;
          this.playPlaying = false;
          this.playVolume = 100;
          this.playMuted = false;
          this.playOpened = true;
        });
        this.$refs["audio"].src = url;
      };
    },
    playClose() {
      this.$refs["audio"].pause();
      this.playOpened = false;
    },
    playChanged(event) {
      this.playPosition = event / 10000 * this.playDuration;
      this.$refs["audio"].currentTime = this.playPosition;
    },
    playUpdated(event) {
      this.playPosition = event.target.currentTime;
      this.playSlider = Math.floor(10000 * this.playPosition / this.playDuration);
    },
    playStart() {
      this.playPlaying = true;
      this.$refs["audio"].play();
    },
    playPause() {
      this.playPlaying = false;
      this.$refs["audio"].pause();
    },
    playRewind() {
      this.$refs["audio"].currentTime = 0;
    },
    playBack() {
      this.playPosition -= 15;
      if(this.playPosition < 0) this.playPosition = 0;
      this.$refs["audio"].currentTime = this.playPosition;
    },
    playForward() {
      this.playPosition += 15;
      if(this.playPosition >= this.playDuration) this.playPosition = this.playDuration;
      this.$refs["audio"].currentTime = this.playPosition;
    },
    playEnd() {
      this.$refs["audio"].currentTime = this.playDuration;
    },
    playMute() {
      this.playMuted = true;
      this.$refs["audio"].volume = 0;
    },
    playUnmute() {
      this.playMuted = false;
      this.$refs["audio"].volume = this.playVolume / 100;
    },
    playSetVolume(value) {
      this.playVolume = value;
      this.playMuted = false;
      this.$refs["audio"].volume = this.playVolume / 100;
    },
    playGetVolume(event) {
      this.playValume = Math.floor(event.target.volume * 100);
    },
    recordShow() {
      var bytes = new Int8Array(64);
      crypto.getRandomValues(bytes);
      this.recordHash = MD5(String.fromCharCode.apply(null, bytes)).toString();
      this.recordLength = 0;
      this.recordActive = false;
      this.recordName = this._("Nagranie") + " " + this.dateTime(Date.now()/1000);
      this.recordBars = [];
      this.recordChunks = [];
      this.recordOpened = true;
      if(!this.recordAllowed) this.enableMicrophone();
    },
    recordStart() {
      this.$refs["timer"].classList.remove("hidden");
      navigator.mediaDevices.getUserMedia({ 
        video: false, 
        audio: true,
      })
      .then((stream) => { 
        this.mediaStream = stream;
        const transaction = this.db.transaction("files", "readwrite");
        const store = transaction.objectStore("files");
        const request = store.add({
          hash: this.recordHash,
          time: Date.now()/1000,
          name: this.recordName,
          incomplete: true,
          synchronized: false,
          length: 0,
          type: null,
          data: null,
        });
        request.onsuccess = (/*event*/) => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (e) => this.recordChunk(e.data);
          this.mediaRecorder.start(1000);
          this.recordActive = true;
          this.recordInterval = window.setInterval(() => this.recordTimer(), 50);
        };
      });
    },
    recordPause() {
      this.$refs["timer"].classList.add("hidden");
      this.mediaRecorder.pause();
      this.mediaRecorder.requestData();
      this.recordBlink = 0;
      this.recordActive = false;
    },
    recordResume() {
      this.$refs["timer"].classList.remove("hidden");
      this.mediaRecorder.resume();
      this.recordActive = true;
    },
    recordChunk(data) {
      this.recordChunks.push(data);
      this.recordSave();
    },
    recordSave() {
      const blob = new Blob(this.recordChunks, { type: this.mediaRecorder.mimeType});
      const transaction = this.db.transaction("files", "readwrite");
      const store = transaction.objectStore("files");
      const request = store.get(this.recordHash);
      request.onsuccess = (event) => {
        const data = event.target.result;
        data.data = blob;
        data.length = this.recordLength;
        data.type = blob.type.replace(/;.*/, "");
        store.put(data);
      };
    },
    recordTimer() {
      if(this.recordActive) {
        this.recordLength += 0.05;
        this.recordBars.push(10*Math.pow(Math.random(), 2));
      } else if(this.recordLength > 0) {
        this.recordBlink++;
        if(this.recordBlink == 10) {
          this.$refs["timer"].classList.remove("hidden");
        }
        if(this.recordBlink == 20) {
          this.$refs["timer"].classList.add("hidden");
          this.recordBlink = 0;
        }
      }
    },
    recordAdd() {
      this.mediaRecorder.stop();
      this.mediaStream.getAudioTracks().forEach((track) => track.stop());
      const transaction = this.db.transaction("files", "readwrite");
      const store = transaction.objectStore("files");
      const request = store.get(this.recordHash);
      request.onsuccess = (event) => {
        const data = event.target.result;
        this.addRecording({
          hash: this.recordHash,
          duration: Math.ceil(this.recordLength),
          title: this.recordName,
          uploaded: false,
          downloaded: true,
          ready: false,
          pending: true,
          time: Math.floor(Date.now()/1000),
        });
        data.incomplete = false;
        data.synchronized = true;
        store.put(data);
        this.recordOpened = false;
        this.recordUpload(this.recordHash);
      };
    },
    recordUpload(hash) {
      this.recordings.forEach((item) => {
        if(item.hash == hash) {
          const transaction = this.db.transaction("files", "readonly");
          const store = transaction.objectStore("files");
          const request = store.get(hash);
          request.onsuccess = (event) => {
            this.$set(this.progress, hash, {
              type: "upload",
              progress: 10,
            });
            const data = event.target.result;
            const form = new FormData();
            form.append("hash", hash);
            form.append("file", data.data);
            form.append("duration", item.duration);
            form.append("title", item.title);
            form.append("time", item.time);
            axios.post(Config.API_URL + "/recording/upload", form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (event) => this.recordProgress(hash, event),
            }).then((/*response*/) => {
              this.$delete(this.progress, hash);
              this.uploadRecording({ hash });
              this.loadList();
            }).catch((/*error*/) => {
              this.$delete(this.progress, hash);
            });
          };
        }
      });
    },
    recordDownload(hash) {
      console.log(hash);
      this.$set(this.progress, hash, {
        type: "download",
        progress: 10,
      });
      axios.get(Config.API_URL + "/recording/download/" + hash, {
        headers: {
          "Accept": "audio/mp3",
        },
        responseType: "blob",
        onDownloadProgress: (event) => this.recordProgress(hash, event),
      }).then((response) => {
        this.$delete(this.progress, hash);
        const item = this.recordings.find((item) => item.hash == hash);
        const transaction = this.db.transaction("files", "readwrite");
        const store = transaction.objectStore("files");
        const request = store.put({
          hash: hash,
          time: Date.now()/1000,
          name: item.title,
          incomplete: false,
          synchronized: true,
          length: 0,
          type: "audio/mp3",
          data: response.data,
        });
        request.onsuccess = () => {
          this.downloadRecording({hash});
        };
      }).catch((/*error*/) => {
        this.$delete(this.progress, hash);
      });
    },
    recordCancel() {
      this.mediaRecorder.stop();
      this.mediaStream.getAudioTracks().forEach((track) => track.stop());
      const transaction = this.db.transaction("files", "readwrite");
      const store = transaction.objectStore("files");
      store.delete(this.recordHash);
      this.recordOpened = false;
    },
    recordProgress(hash, event) {
      this.progress[hash].progress = parseInt(event.progress * 100);
    },
    pendingRemove(index) {
      const hash = this.pendingList[index].hash;
      const transaction = this.db.transaction("files", "readwrite");
      const store = transaction.objectStore("files");
      const request = store.delete(hash);
      request.onsuccess = () => {
        this.pendingList.splice(index, 1);
        if(!this.pendingList.length) this.pendingOpened = false;
      };
    },
    pendingSave(index) {
      const hash = this.pendingList[index].hash;
      this.addRecording({
        hash: hash,
        duration: this.pendingList[index].duration,
        title: this.pendingList[index].title,
        uploaded: false,
        downloaded: true,
        ready: false,
        pending: true,
        time: this.pendingList[index].time,
      });
      this.recordUpload(hash);
      this.pendingList.splice(index, 1);
      if(!this.pendingList.length) this.pendingOpened = false;
      const transaction = this.db.transaction("files", "readwrite");
      const store = transaction.objectStore("files");
      const request = store.get(hash);
      request.onsuccess = (event) => {
        const data = event.target.result;
        if(data) {
          data.incomplete = false;
          store.put(data);
        }      
      };
    },
    ...mapMutations(["finishRecordings", "addRecording", "downloadRecording", "removeRecording", "uploadRecording"]),
    ...mapActions(["loadRecordings", "renameRecording", "deleteRecording"])
  },
}
</script>

<style>

.play-info {
  height: 200px;
}

.play-info .v-input__prepend-outer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  padding: 0 6px;
  margin-bottom: -6px;
}

.play-buttons {
  display: flex;  
  justify-content: center;
  align-items: center;
}
.play-buttons > DIV {
  display: flex;
  gap: 10px;
  align-items: center;
}
@media screen and (max-width: 389px)
{
  .play-buttons > DIV { gap: 10px; }
}

.play-volume {
  padding-top: 15px;
  padding-bottom: 3px;
  background: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.record-info {
  height: 260px;
}

.record-blocked {
  height: 250px;
  display: flex;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.record-blocked I::before {
  font-size: 100px;
}

.record-time {
  font-size: 64px;
  color: black;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
.record-time.hidden {
  visibility: hidden;
}

.record-buttons {
  display: flex;  
  justify-content: space-between;
  align-items: center;
}
.record-buttons DIV:first-child {
  width: 120px;
  text-align: left;
}
.record-buttons DIV:last-child {
  width: 120px;
  text-align: right;
}

.record-buttons BUTTON.v-btn--rounded,
.play-buttons BUTTON.v-btn--rounded {
  padding: 0 !important;
}
.play-buttons BUTTON.v-btn--rounded.v-size--large,
.record-buttons BUTTON.v-btn--rounded.v-size--large {
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 20px;
}
.play-buttons BUTTON.v-btn--rounded.v-size--x-large,
.record-buttons BUTTON.v-btn--rounded.v-size--x-large {
  height: 72px !important;
  min-width: 72px !important;
  border-radius: 40px;
}
.play-buttons BUTTON.v-btn--rounded .v-icon,
.record-buttons BUTTON.v-btn--rounded .v-icon {
  padding: 0;
  margin: 0 !important;
}

.record-buttons BUTTON:not(.v-btn--rounded) {
  width: 120px;
}

.record-bars {
  display: flex;
  margin-bottom: 20px;
  overflow-x: hidden;
  flex-direction: row-reverse;
}

.record-bars > DIV {
  display: flex;
  height: 20px;
  align-items: center;
}

.record-bars > DIV > DIV {
  width: 3px;
  margin-left: 1px;
  background: #7f8c8d;
}

</style>
