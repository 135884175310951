export default {
  install(Vue /*, options */) {
    Vue.prototype._ = function(text) {
      return text;
      /*
      var ret = translations[this.$store.getters["locale"]][text];
      if(ret)
        return ret;
      else
        return text;
      */
    }
  },
}