//import translations from "../includes/locale.js"

export default {
  install(Vue /*, options */) {
    Vue.prototype.fileSize = function(size) {
      var r;
      if(size >= 1024*1024*1024) {
        r = size / (1024*1024*1024);
        if(parseInt(r) == r)
          return r + ' GB';
        else
          return parseFloat(r).toFixed(r >= 10 ? 1 : 2) + " GB";
      }
      if(size >= 1024*1024) {
        r = size / (1024*1024);
        if(parseInt(r) == r)
          return r + ' MB';
        else
          return parseFloat(r).toFixed(r >= 10 ? 1 : 2) + " MB";
      }
      else if(size >= 1024) {
        r = size / 1024;
        if(parseInt(r) == r)
          return r + ' kB';
        else
          return parseFloat(r).toFixed(r >= 10 ? 1 : 2) + " kB";
      }
      else return size + ' ' + this._("bajtów");
    };
    Vue.prototype.fileDuration = function(time) {
      var duration = Math.ceil(time);
      console.trace();
      var result = "";
      if(duration > 3600) {
        const h = Math.floor(duration/3600);
        duration = duration - h*3600;
        result = h.toString() + ":";
      }
      const m = Math.floor(duration/60);
      duration = duration - m*60;
      result = result + m.toString().padStart(2, "0") + ":" + duration.toString().padStart(2, "0");
      return result;
    };
    Vue.prototype._date = function(date) {
      var y = date.getFullYear();
      var m = '0' + (date.getMonth()+1);
      var d = '0' + date.getDate();
      return y + '-' + m.substr(-2) + '-' + d.substr(-2);
    };
    Vue.prototype._time = function(timestamp) {
      var date = new Date(timestamp*1000);
      var h = '0' + date.getHours();
      var m = '0' + date.getMinutes();
      //var s = '0' + date.getSeconds();
      return h.substr(-2) + ':' + m.substr(-2); // + ':' + s.substr(-2);
    };
    Vue.prototype.dateTime = function(timestamp) {
      var date = new Date(timestamp*1000);
      var s = '0' + date.getSeconds();
      return this._date(date) + " " + this._time(timestamp) + ':' + s.substr(-2);
    };
    Vue.prototype.prettyDate = function(timestamp, at = true) {
      var now = new Date();
      var date = new Date(timestamp*1000);
      var formatted = this._date(date);
      if(this._date(now) == formatted)
        return at ? this._('Dzisiaj o') : this._('Dzisiaj');
      else if(now < date) {
        now.setDate(now.getDate()+1);
        if(this._date(now) == formatted)
          return at ? this._('Jutro o') : this._('Jutro');
        now.setDate(now.getDate()+1);
        if(this._date(now) == formatted)
          return at ? this._('Pojutrze o') : this._('Pojutrze');
        return formatted;
      }
      else {
        now.setDate(now.getDate()-1);
        if(this._date(now) == formatted)
          return at ? this._('Wczoraj o') : this._('Wczoraj');
        now.setDate(now.getDate()-1);
        if(this._date(now) == formatted)
          return at ? this._('Przedwczoraj o') : this._('Przedwczoraj');
        return formatted;
      }
    };
    Vue.prototype.prettyTime = function(timestamp) {
      return this.prettyDate(timestamp) + " " + this._time(timestamp);
    };
    Vue.prototype.dateLabel = function(timestamp) {
      var now = new Date();
      var now2 = new Date();
      var date = new Date(timestamp*1000);
      var nowD = now.getDay()-1;
      if(nowD < 0) nowD = 6;
      var dateD = date.getDay()-1;
      if(dateD < 0) dateD = 6;
      var formatted = this._date(date);

      if(this._date(now) == formatted)
        return this._('Dzisiaj');
      else if(now < date) {
        now2.setDate(now.getDate()+1);
        if(this._date(now2) == formatted)
          return this._('Jutro');
        now2 = new Date();
        now2.setDate(now.getDate()+2);
        if(this._date(now2) == formatted)
          return this._('Pojutrze');
        now2 = new Date();
        now2.setDate(now.getDate()+dateD-nowD);
        if(this._date(now2) == formatted)
          return this._('W tym tygodniu');
        now2 = new Date();
        now2.setDate(now.getDate()+7+dateD-nowD);
        if(this._date(now2) == formatted)
          return this._('W przyszłym tygodniu');
        now2 = new Date();
        now2.setDate(now.getDate()+14+dateD-nowD);
        if(this._date(now2) == formatted)
          return this._('Za dwa tygodnie');
        if(this._date(now).substr(0,7) == formatted.substr(0,7))
          return this._('W tym miesiącu');
        now2 = new Date();
        now2.setMonth(now.getMonth()+1);
        if(this._date(now2).substr(0,7) == formatted.substr(0,7))
          return this._('W przyszłym miesiącu');
        if(this._date(now).substr(0,4) == formatted.substr(0,4))
          return this._('W tym roku');
        var diff = date.getFullYear() - now.getFullYear();
        if(diff == 1)
          return this._('W przyszłym roku');
        if(diff >= 2 && diff <= 4)
          return this._('Za') + ' ' + diff + ' ' + this._('lata');
        else
          return this._('Za') + ' ' + diff + ' ' + this._('lat');
      }
      else {
        now2.setDate(now.getDate()-1);
        if(this._date(now2) == formatted)
          return this._('Wczoraj');
        now2 = new Date();
        now2.setDate(now.getDate()-2);
        if(this._date(now2) == formatted)
          return this._('Przedwczoraj');
        now2 = new Date();
        now2.setDate(now.getDate()+dateD-nowD);
        if(this._date(now2) == formatted)
          return this._('W tym tygodniu');
        now2 = new Date();
        now2.setDate(now.getDate()-7+dateD-nowD);
        if(this._date(now2) == formatted)
          return this._('W ubiegłym tygodniu');
        now2 = new Date();
        now2.setDate(now.getDate()-14+dateD-nowD);
        if(this._date(now2) == formatted)
          return this._('Dwa tygodnie temu');
        if(this._date(now).substr(0,7) == formatted.substr(0,7))
          return this._('W tym miesiącu');
        now2 = new Date();
        now2.setMonth(now.getMonth()-1);
        if(this._date(now2).substr(0,7) == formatted.substr(0,7))
          return this._('W ubiegłym miesiącu');
        if(this._date(now).substr(0,4) == formatted.substr(0,4))
          return this._('W tym roku');
        var diff2 = now.getFullYear() - date.getFullYear();
        if(diff2 == 1)
          return this._('W ubiegłym roku');
        if(diff2 >= 2 && diff2 <= 4)
          return diff + ' ' + this._('lata temu');
        else
          return diff + ' ' + this._('lat temu');
      }    
    };
  },
}