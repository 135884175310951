<template>
  <page>

    <div class="d-flex flex-column flex-sm-row justify-space-between align-center">
      <h1>
        {{ _('Twoje notatki') }}:
      </h1>
      <div class="upload-buttons">
        <v-btn x-large @click.stop="youtubeShow">
          <v-icon>
            mdi-youtube
          </v-icon>
          <span class="d-none d-md-inline">
            {{ _('Wybierz wideo YouTube') }}
          </span>
          <span class="d-inline d-md-none">
            {{ _('YouTube') }}
          </span>
        </v-btn>
        <v-btn x-large color="primary" @click.stop="uploadShow">
          <v-icon>
            mdi-upload
          </v-icon>
          <span class="d-none d-md-inline">
            {{ _('Wczytaj pliki z dysku') }}
          </span>
          <span class="d-inline d-md-none">
            {{ _('Pliki') }}
          </span>
        </v-btn>
      </div>
    </div>

    <div v-if="!transcriptsLoaded" class="mt-2">
      <v-skeleton-loader
        max-width="400"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        max-width="350"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        max-width="450"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </div>

    <component-error v-else-if="transcriptsError" @click="loadTranscripts"/>

    <v-list two-line v-else>
      <v-list-item
        v-for="(item, index) in transcripts"
        :key="index"
      >

        <v-list-item-avatar>
          <v-icon
            :class="itemClass(item)"
            dark
          >
            {{ itemIcon(item) }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <template v-if="editingIndex != index || editingType != 'list'">
              <v-btn icon x-small @click="editStart(index, 'list')" class="d-none d-sm-inline">
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
              {{ item.title }}
            </template>
            <div class="edit-title" v-else>
              <v-text-field filled dense v-model="editingTitle" ref="title" @blur="editStop({code:'Enter'})" @keyup="editStop"/>
            </div>
          </v-list-item-title>
          <v-list-item-subtitle style="height: 18px">
            <span v-if="item.finished || item.error" :class="{ 'edit-subtitle': editingIndex == index && editingType == 'list'}">
              <span class="d-none d-sm-inline">
                {{ prettyTime(item.time) }}
                |
              </span>
              {{ itemInfo(item) }}
            </span>
            <v-progress-linear v-else :value="item.percent" class="mt-1"/>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <div class="d-flex align-center">
            <div class="text-h6 item-duration">
              {{ fileDuration(item.duration) }}
            </div>
            <v-btn @click="itemDownload(item.hash)" :disabled="!item.finished">
              <v-icon small>
                mdi-download
              </v-icon>
              <span class="d-none d-sm-inline">
                {{ _('Pobierz plik') }}
              </span>
            </v-btn>
          </div>
        </v-list-item-action>

      </v-list-item>
    </v-list>

    <v-dialog
      v-model="uploadOpened"
      max-width="700"
      :persistent="uploadActive"
    >
      <v-card @dragover.prevent @drop.prevent="dragFinished($event)" @dragenter="dragActive++" @dragleave="dragActive--" :class="{ 'drag-active': dragActive }">

        <v-card-title class="justify-space-between">
          <span>
            {{ _('Wczytaj pliki dźwiękowe') }}
          </span>
          <v-btn icon @click="uploadOpened = false" v-if="!uploadActive">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <div v-if="hasUploads">

            <div class="dialog-list">

              <v-list two-line>
                <template v-for="(item, index) in uploadFiles">
                  
                  <v-list-item v-if="item.visible" :key="index">
                    <v-list-item-avatar>
                      <v-icon
                        :class="fileClass(item)"
                        dark
                      >
                        {{ fileIcon(item) }}
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <template v-if="editingIndex != index || editingType != 'upload'">
                          <v-btn icon x-small @click="editStart(index, 'upload')" :disabled="uploadActive" class="d-none d-sm-inline">
                            <v-icon small>
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          {{ item.title }}
                        </template>
                        <div class="edit-title" v-else>
                          <v-text-field filled dense v-model="editingTitle" ref="title" @blur="editStop({code:'Enter'})" @keyup="editStop"/>
                        </div>
                        </v-list-item-title>
                      <v-list-item-subtitle style="height:18px" :class="{ 'edit-subtitle': editingIndex == index && editingType == 'upload'}">
                        <span v-text="fileInfo(item)" v-if="!item.uploading || item.encoding"/>
                        <v-progress-linear v-else :value="item.progress" class="mt-1"/>
                        <span v-if="item.canRetry">
                          &nbsp;<a href="#" @click.prevent="fileRetry(index)">{{ _('Ponów próbę') }}</a>
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <div class="d-flex align-center">
                        <div v-if="item.duration" class="text-h6">
                          {{ fileDuration(item.duration) }}
                        </div>
                        <div class="ml-2 d-none d-sm-block">
                          <v-btn icon @click="fileRemove(index)" v-if="!item.uploading">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn icon @click="uploadCancel()" v-else>
                            <v-icon>mdi-empty</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-list-item-action>

                  </v-list-item>

                  <div class="upload-meta" dense v-if="item.visible && !item.error && !item.pending && !item.uploading" :key="'b'+index">
                    <div class="d-flex upload-meta-fields">
                      <v-select dense v-model="item.language" outlined :items="languages" :label="_('Język nagrania')+':'" :disabled="uploadActive">
                        <template v-slot:selection="data">
                          <component-flag :value="data.item.value"/>
                          {{ data.item.text }}
                        </template>
                        <template v-slot:item="data">
                          <component-flag :value="data.item.value"/>
                          {{ data.item.text }}
                        </template>
                      </v-select>
                      <div></div>
                    </div>
                  </div>

                </template>
              </v-list>

              <div class="text-center mb-2" v-if="!uploadActive">
                <v-btn @click="uploadMore">
                  <v-icon>mdi-plus</v-icon>
                  {{ _('Wczytaj więcej plików') }}
                </v-btn>
              </div>

            </div>

          </div>

          <div class="upload-empty" v-else>

            <v-btn @click="uploadMore" x-large>
              <v-icon>mdi-upload</v-icon>
              {{ _('Wczytaj pliki z dysku') }}
            </v-btn>

            <div class="mt-2">
              {{ _('lub przeciągnij i upuść je tutaj.') }}
            </div>

          </div>

        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn @click="uploadStart" large color="primary" :disabled="!uploadPossible || uploadActive" :loading="uploadActive" class="px-6">
            <span class="d-none d-sm-inline">
              {{ _('Prześlij pliki i stwórz notatki') }}
            </span>
            <span class="d-inline d-sm-none">
              {{ _('Stwórz notatki z plików') }}
            </span>
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <v-dialog
      v-model="youtubeOpened"
      max-width="700"
    >
      <v-card>

        <v-card-title class="justify-space-between">
          <span>
            {{ _('Wybierz video YouTube') }}
          </span>
          <v-btn icon @click="youtubeOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <div class="dialog-list">

            <v-list two-line>
              <template v-for="(item, index) in youtubeVideos">
                
                <v-list-item v-if="item.visible" :key="index">
                  <v-list-item-avatar>
                    <v-icon
                      :class="fileClass(item)"
                      dark
                    >
                      {{ fileIcon(item) }}
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      <template v-if="editingIndex != index || editingType != 'youtube'">
                        <v-btn icon x-small @click="editStart(index, 'youtube')" :disabled="!item.title" class="d-none d-sm-inline">
                          <v-icon small>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        {{ item.title ? item.title : item.url }}
                      </template>
                      <div class="edit-title" v-else>
                        <v-text-field filled dense v-model="editingTitle" ref="title" @blur="editStop({code:'Enter'})" @keyup="editStop"/>
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle style="height:18px" :class="{ 'edit-subtitle': editingIndex == index && editingType == 'youtube'}">
                      <span v-text="youtubeInfo(item)"/>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="d-flex align-center">
                      <div v-if="item.duration" class="text-h6">
                        {{ fileDuration(item.duration) }}
                      </div>
                      <div class="ml-2">
                        <v-btn icon @click="youtubeRemove(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-action>

                </v-list-item>

                <div class="upload-meta" dense v-if="item.visible && !item.error && !item.pending" :key="'b'+index">
                  <div class="d-flex upload-meta-fields">
                    <v-select dense v-model="item.language" outlined :items="languages" :label="_('Język nagrania')+':'" :disabled="uploadActive">
                      <template v-slot:selection="data">
                        <component-flag :value="data.item.value"/>
                        {{ data.item.text }}
                      </template>
                      <template v-slot:item="data">
                        <component-flag :value="data.item.value"/>
                        {{ data.item.text }}
                      </template>
                    </v-select>
                    <div></div>
                  </div>
                </div>

              </template>
            </v-list>

            <div class="d-flex">
              <v-text-field v-model="youtubeUrl" outlined clearable  :error-messages="youtubeError ? [youtubeError] : null" ref="youtube" placeholder="https://www.youtube.com/watch?v=Ks-_Mh1QhMc" @keyup="youtubeAdd" :label="_('Adres video w YouTube')" class="youtube-url" @click:clear="youtubeError=''"/>
              &nbsp;
              <v-btn x-large @click="youtubeAdd({code:'Enter'})">
                <span class="d-none d-sm-inline">
                  {{ _('Dodaj') }}
                </span>
                <span class="d-inline d-sm-none px-1">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </span>
              </v-btn>
            </div>

          </div>

        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn @click="youtubeStart" large color="primary" :disabled="!youtubePossible || uploadActive" :loading="uploadActive" class="px-6">
            <span class="d-none d-sm-inline">
              {{ _('Stwórz notatki z wideo YouTube') }}
            </span>
            <span class="d-inline d-none">
              {{ _('Stwórz notatki z wideo') }}
            </span>
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <input type="file" ref="files" accept=".3gp,.aac,.aiff,.au,.avi,.f4v,.flac,.flv,.m4a,.mkv,.mp3,.mp4,.mpg,.mpeg,.ogg,.ogv,.opus,.wav,.webm,.wma,.wmv" multiple @change="filesAdded" class="upload-input" v-if="uploadOpened">

  </page>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Page from '../Page.vue';
import ComponentError from '../components/Error.vue';
import ComponentFlag from '../components/Flag.vue';

const axios = require('axios');

/*global Config*/

const MAX_VIDEO_SIZE = 512*1024*1024;

export default {
  name: 'PageTranscripts',
  components: {
    Page,
    ComponentError,
    ComponentFlag,
  },
  data() {
    return {
      uploadOpened: false,
      uploadFiles: [],
      uploadActive: false,
      uploadIndex: 0,
      uploadErrors: false,
      dragActive: 0,
      youtubeOpened: false,
      youtubeVideos: [],
      youtubeUrl: "",
      youtubeError: "",
      editingIndex: 0,
      editingType: null,
      editingTitle: 0,
    };
  },
  computed: {
    languages() {
      return [
        {value: "pl", text: this._("Polski") },
        {value: "en", text: this._("Angielski") },
      ];
    },
    hasUploads() {
      for(let i = 0; i < this.uploadFiles.length; i++) {
        if(this.uploadFiles[i].visible) return true;
      }
      return false;
    },
    uploadPossible() {
      var ok = false;
      for(let i = 0; i < this.uploadFiles.length; i++) {
        if(!this.uploadFiles[i].visible) continue;
        if(this.uploadFiles[i].error) continue;
        if(this.uploadFiles[i].pending) return false;
        if(!this.uploadFiles[i].name.trim().length) return false;
        if(!this.uploadFiles[i].error) ok = true;
      }
      return ok;
    },
    youtubePossible() {
      var ok = false;
      for(let i = 0; i < this.youtubeVideos.length; i++) {
        if(!this.youtubeVideos[i].visible) continue;
        if(this.youtubeVideos[i].pending) return false;
        if(!this.youtubeVideos[i].error) ok = true;
      }
      return ok;
    },
    ...mapGetters(["transcripts", "transcriptsLoaded", "transcriptsError"]),
  },
  mounted() {
    this.loadTranscripts();
  },
  methods: {
    itemDownload(hash) {
      window.location = Config.API_URL + "/transcript/download/" + hash;
    },
    itemClass(item) {
      if(item.error) {
        return "error";
      } else if(item.finished) {
        return "success";
      } else {
        return "grey loading";
      }
    },
    itemIcon(item) {
      if(item.error) {
        return "mdi-close";
      } else if(item.finished) {
        return "mdi-check";
      } else {
        return "mdi-loading";
      }
    },
    itemTime(item) {
      const date = new Date(item.time * 1000);
      return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    },
    itemInfo(item) {
      if(item.error) {
        return this._("Błąd podczas przetwarzania pliku");
      } else if(item.finished) {
        return this._("Transkrypcja gotowa.");
      } else {
        return this._("Trwa tworzenie transkrypcji...");
      }
    },
    editStart(index, type) {
      this.editingIndex = index;
      this.editingType = type;
      switch(type) {
        case "list":
          this.editingTitle = this.transcripts[index].title;
          break;
        case "upload":
          this.editingTitle = this.uploadFiles[index].title;
          break;
        case "youtube":
          this.editingTitle = this.youtubeVideos[index].title;
          break;
      }
      this.$nextTick(() => document.querySelector("DIV.edit-title INPUT").focus());
    },
    editStop(event) {
      if(event.code != "Enter" && event.code != "Escape") return;
      if(event.code == "Enter" && this.editingTitle.trim().length > 0) switch(this.editingType) {
        case "list":
          this.renameTranscript({
            hash: this.transcripts[this.editingIndex].hash,
            title: this.editingTitle.trim()
          });
          break;
        case "upload":
          this.uploadFiles[this.editingIndex].title = this.editingTitle.trim();
          break;
        case "youtube":
          this.youtubeVideos[this.editingIndex].title = this.editingTitle.trim();
          break;
      }
      this.editingType = null;
    },
    dragFinished(event) {
      this.dragActive = 0;
      this.uploadAdd(event.dataTransfer.files);
    },
    uploadShow() {
      this.uploadFiles = [];
      this.uploadOpened = true;
      this.uploadActive = false;
      this.$nextTick(() => {
        this.$refs["files"].click();
      });
    },
    uploadMore() {
      this.$refs["files"].click();
    },
    uploadAdd(files) {
      for(let i = 0; i < files.length; i++) {
        const index = this.fileAdd(files[i]);
        if(files[i].type.startsWith("audio/") || files[i].type.startsWith("video/")) {
          if(files[i].size < MAX_VIDEO_SIZE) {
            /*
            if(files[i].type.startsWith("video/")) {
              const context = new AudioContext();
              const reader = new FileReader();
              reader.onloadend = (e) => {
                if(e.target.result) {
                  context.decodeAudioData(e.target.result, (result) => {
                    this.uploadFiles[index].pending = false;
                    this.uploadFiles[index].duration = result.duration;
                    this.uploadFiles[index].buffer = result;
                  });
                } else {
                  this.uploadFiles[index].error = this._("Nie można odczytać pliku.");
                }
              };
              reader.readAsArrayBuffer(files[i]);
            } else */ {
              const audio = document.createElement("AUDIO");
              audio.preload = "metadata";
              audio.onloadedmetadata = () => {
                this.uploadFiles[index].pending = false;
                this.uploadFiles[index].duration = audio.duration;
                this.uploadFiles[index].audio = audio;
              };
              audio.onerror = () => {
                this.uploadFiles[index].error = this._("Nie można odczytać pliku.");
              };
              audio.src = window.URL.createObjectURL(files[i]);
            }
          } else {
            this.uploadFiles[index].error = this._("Zbyt duży plik. Maksymalny rozmiar pliku to 500 MB, plik ma") + " " + this.fileSize(files[i].size) + ".";
          }
        } else {
          this.uploadFiles[index].error = this._("Nieprawidłowy typ pliku.");
        }
      }
    },
    uploadStart() {
      this.uploadActive = true;
      document.querySelector(".dialog-list").scrollTop = 0;
      for(let i = 0; i < this.uploadFiles.length; i++) {
        const file = this.uploadFiles[i];
        if(file.error || file.pending) file.visible = false;
        if(file.visible && !file.uploading) {
          this.uploadIndex = i;
          const form = new FormData();
          form.append("file", file.src);
          form.append("duration", file.duration);
          form.append("title", file.title);
          form.append("language", file.language);
          file.uploading = true;
          file.canRetry = false;
          axios.post(Config.API_URL + "/transcript/upload", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => this.uploadProgress(event),
          }).then((/*response*/) => {
            file.visible = false;
            file.uploading = false;
            this.loadTranscripts();
            this.uploadStart();
          }).catch((/*error*/) => {
            file.error = this._("Nie udało się wysłać pliku.");
            file.uploading = false;
            file.canRetry = true;
            this.uploadErrors = true;
            this.uploadStart();
          });
          return;
        }
      }
      this.uploadActive = false;
      if(!this.uploadErrors) this.uploadOpened = false;
      this.uploadErrors = false;
    },
    uploadProgress(event) {
      this.uploadFiles[this.uploadIndex].progress = parseInt(event.progress * 100);
    },
    uploadCancel() {
//      this.cancelController.abort();
    },
    filesAdded() {
      this.uploadAdd(this.$refs["files"].files);
    },
    fileAdd(file) {
      var name = file.name.replace(/\.[^.]+$/, "");
      if(!name) name = file.name;
      const item = {
        name: file.name,
        size: file.size,
        src: file,
        visible: true,
        pending: true,
        uploading: false,
        finished: false,
        progress: 0,
        title: name,
        language: "pl",
        error: null,
        canRetry: false,
        video: file.type.startsWith("video/"),
        encoding: false,
      };
      this.uploadFiles.push(item);
      return this.uploadFiles.length - 1;
    },
    fileRemove(index) {
      this.uploadFiles[index].visible = false;
    },
    fileRetry(index) {
      this.uploadFiles[index].error = "";
      this.uploadFiles[index].canRetry = false;
    },
    fileClass(file) {
      if(file.error) {
        return "error";
      } else if(file.pending) {
        return "grey loading";
      } else if(file.uploading) {
        return "primary uploading";
      } else {
        return "primary";
      }
    },
    fileIcon(file) {
      if(file.error) {
        return "mdi-close";
      } else if(file.pending) {
        return "mdi-loading";
      } else if(file.uploading) {
        return "mdi-upload";
      } else {
        if(file.video)
          return "mdi-video";
        else
          return "mdi-volume-high";
      }
    },
    fileInfo(file) {
      if(file.error) {
        return file.error;
      } else if(file.pending) {
        return this._("Sprawdzam zawartość pliku...");
      } else {
        return this._("Gotowy do wysłania") + " (" + this.fileSize(file.size) + ")";
      }
    },
    youtubeShow() {
      this.youtubeVideos = [];
      this.youtubeOpened = true;
      this.youtubeError = "";
      this.youtubeUrl = "";
      this.uploadActive = false;
      window.setTimeout(() => {
        this.$refs["youtube"].focus();
      }, 100);
    },
    youtubeAdd(event) {
      if(event.code == "Enter") {
        this.youtubeError = "";
        var id = null, match;
        match = this.youtubeUrl.match(/youtube\.com\/watch\?v=([A-Za-z0-9_-]+)/);
        if(match) {
          id = match[1];
        } else {
          match = this.youtubeUrl.match(/youtu\.be\/([A-Za-z0-9_-]+)/);
          if(match) {
            id = match[1];
          }
        }
        if(id) {
          const index = this.youtubeVideos.length;
          this.youtubeVideos.push({
            url: this.youtubeUrl,
            id: id,
            title: "",
            visible: true,
            pending: true,
            duration: 0,
            language: "pl",
            error: "",
          });
          this.youtubeUrl = "";
          this.$refs["youtube"].focus();

          const form = new FormData();
          form.append("id", id);
          axios.post(Config.API_URL + "/youtube/info", form, {
          }).then((response) => {
            this.youtubeVideos[index].pending = false;
            this.youtubeVideos[index].duration = response.data.duration;
            this.youtubeVideos[index].title = response.data.title;
          }).catch((/*error*/) => {
            this.youtubeVideos[index].error = this._("Nie można pobrać informacji.");
          });
        } else {
          this.youtubeError = this._("Nieprawidłowy adres wideo YouTube.")
        }
      }
    },
    youtubeInfo(item) {
      if(item.error) {
        return item.error;
      } else if(item.pending) {
        return this._("Sprawdzam nagranie wideo...");
      } else {
        return this._("Gotowe do transkrypcji.");
      }
    },
    youtubeRemove(index) {
      this.youtubeVideos[index].visible = false;
    },
    youtubeStart() {
      this.uploadActive = true;
      document.querySelector(".dialog-list").scrollTop = 0;
      const form = new FormData();
      for(let i = 0; i < this.youtubeVideos.length; i++) {
        const item = this.youtubeVideos[i];
        if(item.visible && !item.pending && !item.error) {
          form.append("ids[]", item.id);
          form.append("durations[]", item.duration);
          form.append("titles[]", item.title);
          form.append("languages[]", item.language);
        }
        axios.post(Config.API_URL + "/youtube/upload", form).then((/*response*/) => {
          this.uploadActive = false;
          this.youtubeOpened = false;
          this.loadTranscripts();
        }).catch((/*error*/) => {
          this.uploadActive = false;
        });
      }
      this.uploadActive = false;
      if(!this.uploadErrors) this.uploadOpened = false;
      this.uploadErrors = false;
    },
    ...mapMutations(["setTranscripts"]),
    ...mapActions(["loadTranscripts", "renameTranscript"])
  },
}
</script>

<style>
.upload-buttons BUTTON:first-child {
  margin-right: 10px;
}
@media only screen and (max-width: 599px) {
  .upload-buttons {
    margin-top: 5px;
    width: 100%;
  }
  .upload-buttons BUTTON {
    width: calc(50% - 5px);
  }
}
@media only screen and (min-width: 600px) {
  .upload-buttons BUTTON {
    min-width: 160px !important;
  }
}

.item-duration {
  margin-right: 15px;
  margin-top: 4px;
}

.upload-input {
  display: none;
}

.upload-empty {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-meta {
  margin: -5px 62px 0 72px;
}
@media only screen and (max-width: 600px) {
  .upload-meta {
    margin: -5px 0 0 0;
  }
}

.upload-meta-fields {
  gap: 10px;
}
.upload-meta-fields > DIV:first-child {
  width: 50%;
}
.upload-meta-fields > DIV:last-child {
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .upload-meta-fields > DIV:first-child {
    width: 65%;
  }
  .upload-meta-fields > DIV:last-child {
    width: 35%;
  }
}

.drag-active {
  border: 5px dashed #1976D2;
}
.drag-active .v-card__title,
.drag-active .v-card__text,
.drag-active .v-card__actions
{
  padding-left: 19px;
  padding-right: 19px;
}

I.v-icon.loading {
  animation: rotation 2s infinite linear;
}
I.v-icon.uploading::before {
  animation: rollup 2s infinite linear;
}
I.v-icon.downloading::before {
  animation: rolldown 2s infinite linear;
}

.youtube-url .v-input__slot {
  min-height: 54px !important;
}

.edit-title .v-input {
  margin-top: -2px !important;
  margin-bottom: -24px !important;
}
.edit-title .v-input__slot {
  min-height: 16px !important;
  padding: 0 24px !important;
}
.edit-subtitle {
  display: block;
  margin-top: -3px;
}
.v-dialog .edit-title .v-input {
  margin-top: -5px !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rollup {
  from {
    height: 4px;
  }
  to {
    height: 32px;
  }
}

@keyframes rolldown {
  from {
    height: 32px;
  }
  to {
    height: 4px;
  }
}
</style>
